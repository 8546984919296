import difference from "lodash-es/difference";
import { AUTH_CONFIG } from "./config";
import { EventEmitter } from "../events";
import { logActivity } from "../log";

export interface AuthConfig {
  domain?: string;
  clientId?: string;
  scope?: string;
  audience?: string;
  callbackUrl?: string;
  logoutUrl?: string;
}

export type Permission = string;

export interface JwtPayload {
  "https://manage.momentum.rent/organisation_id": number;
  "https://manage.momentum.rent/email_verified": boolean;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: Permission[];
}

export declare class User {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
}

export default abstract class AuthAbstract extends EventEmitter {
  protected readonly domain: string;
  protected readonly clientId: string;
  protected readonly callbackUrl: string;
  protected readonly logoutUrl: string;
  protected readonly scope: string;
  protected readonly audience: string;

  public permissions: Permission[] = [];

  public user?: User;

  constructor(config?: AuthConfig) {
    super();
    this.domain = config?.domain ?? AUTH_CONFIG.domain!;
    this.clientId = config?.clientId ?? AUTH_CONFIG.clientId!;
    this.callbackUrl = config?.callbackUrl ?? AUTH_CONFIG.callbackUrl!;
    this.logoutUrl = config?.logoutUrl ?? AUTH_CONFIG.logoutUrl!;
    this.scope = config?.scope ?? "openid profile email";
    this.audience = config?.audience ?? "https://m0m.auth.api/";

    this.init();
  }
  public abstract init(): Promise<any>;
  public abstract getToken(): Promise<string>;
  public abstract getUser(): Promise<User | undefined>;

  beforeLogin() {
    this.emit("login:start");
  }

  public abstract login(): Promise<any>;

  async afterLogin() {
    const token = await this.getToken();
    await this.parseToken(token);
    this.user = await this.getUser();

    logActivity({
      entity: "auth",
      action: "login",
      success: true,
    });

    this.emit("login:success");
  }

  public abstract logout(): Promise<void>;

  afterLogout() {
    logActivity({
      entity: "auth",
      action: "logout",
      success: true,
    });
    this.emit("logout:success");
  }

  public abstract isAuthenticated(): Promise<boolean>;

  public async isAllowed(askedPermissions: Permission[]) {
    const isAuthenticated = await this.isAuthenticated();
    return (
      isAuthenticated &&
      difference(askedPermissions, this.permissions).length === 0
    );
  }

  public async parseToken(token: string) {
    const { default: decode } = await import(
      "jwt-decode" /* webpackPrefetch: true */
    );
    const decoded: JwtPayload = decode(token);
    this.permissions = decoded.permissions;
  }
}
