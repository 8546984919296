import { auth } from "../services/auth/client";

export interface Activity {
  entity: string;
  action: string;
  success: boolean;
}

export async function logActivity(activity: Activity) {
  const token = await auth.getToken();

  fetch(`${process.env.REACT_APP_API_URL}activity-logs`, {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(activity),
  });
}
