import { IonLoading } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../services/auth/client";

export const Authenticating: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    (async function () {
      const isAuthenticated = await auth.isAuthenticated();
      if (isAuthenticated && history) history.replace("/");
    })();
  }, [history]);

  return (
    <IonLoading
      message={"Checking session..."}
      duration={15000}
      isOpen={true}
    />
  );
};

export default Authenticating;
