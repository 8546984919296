import * as React from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import { Loading } from "../components/Loading";

const Intro = React.lazy(
  () => import("../pages/public/Intro") /* webpackChunkName: "intro" */
);

const AuthCallback = React.lazy(
  () => import("../pages/auth/AuthCallback") /* webpackChunkName: "auth-cb" */
);

const Public: React.FC = () => {
  return (
    <IonRouterOutlet>
      <Route path="/" exact={true}>
        <Redirect to="/intro" />
      </Route>
      <Route path="/hello" exact={true}>
        <Redirect to="/intro" />
      </Route>
      <Route path="/intro">
        <React.Suspense fallback={<Loading />}>
          <Intro />
        </React.Suspense>
      </Route>
      <Route path="/auth" exact={true}>
        <React.Suspense fallback={<Loading />}>
          <AuthCallback />
        </React.Suspense>
      </Route>
      <Route>
        <Redirect to="/intro" />
      </Route>
    </IonRouterOutlet>
  );
};

export default Public;
