// @ts-check
import * as React from "react";
import { request } from "./request";
import auth from "./auth/client";
import { EventEmitter } from "./events";

const defaultUser: Partial<ReelUser> = {
  loading: true,
};

class User extends EventEmitter {
  user: ReelUser = defaultUser as ReelUser;

  get defaultLocation(): Location | undefined {
    return this.user.locations?.[0];
  }
  constructor() {
    super();
    this.init();
    auth.on("login:success", () => {
      this.init();
    });
    auth.on("logout:success", () => {
      this.destroy();
    });
  }

  async init() {
    if (
      (await auth.isAuthenticated()) &&
      (await auth.getUser()) &&
      !this.isLoaded()
    ) {
      this.fetchUserInfo();
    }
  }

  /**
   * Flag that tells us if we have already fetched the user
   */
  isLoaded() {
    return !this.user.loading;
  }

  async fetchUserInfo() {
    if (this.isLoaded()) {
      this.emit("user:loaded", this.user);
      return;
    }

    try {
      const profile = await request({ endpoint: "profile" });
      const authUser = await auth.getUser();
      this.user = { ...profile, ...authUser };
      this.emit("user:loaded", this.user);
    } catch (e) {
      // Forces error to affect render so error boundary can catch it.
      throw new Error("Unknown User");
    }
  }

  destroy() {
    this.user = defaultUser as ReelUser;
    this.emit("user:destroyed");
  }
}

const user = new User();

export const useUser = () => {
  const [, setReelUser] = React.useState<ReelUser>(user.user);
  React.useEffect(() => user.on("user:loaded", setReelUser), []);
  return user;
};
