import * as React from "react";

export const useAsyncError = () => {
  const [, setError] = React.useState();
  return React.useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

export default useAsyncError;

export class ApiError extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = "ApiError";
  }
}

export const authErrorTypes = {
  INACTIVE_USER: "inactive_user",
  UNVERIFIED_EMAIL: "unverified_email",
  UNAUTHORIZED: "unauthorized",
};

export class AuthError extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = "AuthError";
  }
}
