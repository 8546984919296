import createAuth0Client from "@auth0/auth0-spa-js";
import type { Auth0Client } from "@auth0/auth0-spa-js";
import AuthAbstract from "./auth";

export class Auth extends AuthAbstract {
  auth0Client?: Auth0Client;

  async init() {
    if (!!this.auth0Client) {
      return Promise.resolve(this.auth0Client);
    }

    const initialization = createAuth0Client({
      domain: this.domain,
      client_id: this.clientId,
      redirect_uri: this.callbackUrl,
      scope: this.scope,
      audience: this.audience,
      cacheLocation: "localstorage",
      onRedirectCallback,
    });

    initialization.then((result) => {
      this.auth0Client = result;
    });

    return initialization;
  }

  async getToken() {
    await this.init();
    return this.auth0Client!.getTokenSilently();
  }

  async getUser() {
    if (!this.user) {
      await this.init();
      this.user = await this.auth0Client!.getUser();
    }
    return this.user;
  }

  async login() {
    await this.init();
    const isAuthenticated = await this.isAuthenticated();

    if (!isAuthenticated) {
      this.beforeLogin();
      try {
        sessionStorage.setItem("redirect_url", window.location.pathname);
      } catch (e) {}
      this.auth0Client!.loginWithRedirect({ redirect_uri: this.callbackUrl });
    }
  }

  async handleRedirectCallback() {
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      await this.init();
      await this.auth0Client!.handleRedirectCallback();
      await this.afterLogin();
      try {
        return sessionStorage.getItem("redirect_url");
      } catch (e) {}
      return "/";
    }
    return false;
  }

  async logout() {
    await this.init();
    await this.auth0Client!.logout({
      returnTo: this.logoutUrl,
    });
    this.afterLogout();
  }

  async isAuthenticated() {
    await this.init();
    return this.auth0Client!.isAuthenticated();
  }
}

export interface AppState {
  targetUrl?: string;
}

const onRedirectCallback = (appState?: AppState) => {
  window.history.replaceState(
    {},
    document.title,
    `${appState?.targetUrl ?? window.location.pathname}${
      window.location.search
    }`
  );
};

export const auth = new Auth();

export default auth;
