export function preloadImage(src: string) {
  if (!src || typeof src !== "string" || src.length < 5) return;
  var res = document.createElement("link");
  res.rel = "preload";
  res.as = "image";
  res.href = src;
  res.crossOrigin = "anonymous";
  document.head.appendChild(res);
}

export function preloadImages(...imgs: string[]) {
  imgs.forEach(preloadImage);
}
