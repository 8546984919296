import * as React from "react";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import Menu from "../components/Menu";
import { Loading } from "../components/Loading";
import { auth } from "../services/auth/client";
import Logout from "../pages/auth/Logout";
import PropertySetter from "./PropertySetter";

const Record = React.lazy(
  () => import("../pages/Record") /* webpackChunkName: "record" */
);
const Signature = React.lazy(
  () => import("../pages/Signature") /* webpackChunkName: "signature" */
);
const Search = React.lazy(
  () => import("../pages/Search") /* webpackChunkName: "search" */
);
const Property = React.lazy(
  () => import("../pages/Property") /* webpackChunkName: "property" */
);
const Damages = React.lazy(
  () => import("../pages/Damages") /* webpackChunkName: "damages" */
);
const Flags = React.lazy(
  () => import("../pages/Flags") /* webpackChunkName: "flags" */
);
const Contract = React.lazy(
  () => import("../pages/Contract") /* webpackChunkName: "contract" */
);
const Complete = React.lazy(
  () => import("../pages/Complete") /* webpackChunkName: "complete" */
);

const PrivateRoutes: React.FC = () => {
  React.useEffect(() => {
    auth.login();
  }, []);
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/" exact={true}>
          <Redirect to="/search" />
        </Route>
        <Route path="/intro" exact={true}>
          <Redirect to="/search" />
        </Route>

        <Route path="/property/:id/damages" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <PropertySetter>
              <Damages />
            </PropertySetter>
          </React.Suspense>
        </Route>
        <Route path="/property/:id/flags" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <PropertySetter>
              <Flags />
            </PropertySetter>
          </React.Suspense>
        </Route>
        <Route path="/property/:id/contract" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <PropertySetter>
              <Contract />
            </PropertySetter>
          </React.Suspense>
        </Route>
        <Route path="/property/:id/record/:target">
          <React.Suspense fallback={<Loading />}>
            <PropertySetter>
              <Record />
            </PropertySetter>
          </React.Suspense>
        </Route>
        <Route path="/property/:id/sign" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <Signature />
          </React.Suspense>
        </Route>
        <Route path="/property/:id/complete" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <Complete />
          </React.Suspense>
        </Route>
        <Route path="/property/:id" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <PropertySetter>
              <Property />
            </PropertySetter>
          </React.Suspense>
        </Route>

        <Route path="/search" exact={true}>
          <React.Suspense fallback={<Loading />}>
            <Search />
          </React.Suspense>
        </Route>
        <Route path="/logout" exact={true}>
          <Logout />
        </Route>
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default PrivateRoutes;
