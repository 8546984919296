import { auth } from "../services/auth/client";
import { ApiError, AuthError, authErrorTypes } from "./error";

const CONFIG = {
  server: `${process.env.REACT_APP_API_URL}`,
  public: false,
};

const DEFAULTS = {
  headers: {},
};

interface RequestParams extends RequestInit {
  endpoint: string;
}

export const useRequest = (config = {}) => {
  const userConfig = { ...CONFIG, ...config };

  const request = async (params: RequestParams) => {
    const token = userConfig.public ? null : await auth.getToken();
    const options = Object.assign(
      {},
      DEFAULTS,
      typeof params === "string" ? { endpoint: params } : params
    );

    const headers: HeadersInit = new Headers({
      "Content-Type": "application/json",
      ...options.headers,
    });

    if (!userConfig.public) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    return fetch(`${userConfig.server}${options.endpoint}`, {
      ...params,
      headers,
    }).then(async (res) => {
      if (res.status === 204) {
        return res;
      }
      if (res.status >= 200 && res.status < 400) {
        //  return json or text
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res.json();
        } else {
          return res.text();
        }
      }
      if (res.status === 401) {
        // await auth.renewSession();

        // if (auth.isAuthenticated()) {
        //   return request(params);
        // }

        throw new AuthError({
          message: "Unauthorized call.",
          type: authErrorTypes.UNAUTHORIZED,
        });
      }
      if (res.status === 403) {
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res.json().then((error) => {
            throw new AuthError(error);
          });
        } else {
          return res.text().then((error) => {
            throw new AuthError({ message: error });
          });
        }
      }
      if (res.status >= 400 && res.status < 500) {
        const error = await res.text();
        throw new ApiError(error);
      }

      throw new Error(`Failed API call: ${res.status} - ${res.statusText}`);
    });
  };
  return request;
};

export default useRequest;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const request = useRequest();
