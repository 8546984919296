import { atom, selectorFamily, selector } from "recoil";
import { getProperty, PropertyID } from "../services/property";

export const currentPropertyID = atom<PropertyID | null>({
  key: "CurrentPropertyID",
  default: null,
});

export const propertyQuery = selectorFamily({
  key: "PropertyQuery",
  get: (userID) => () => !userID ? null : getProperty(userID as PropertyID),
});

export const currentPropertyQuery = selector({
  key: "CurrentPropertyQuery",
  get: ({ get }) => {
    if (get(currentPropertyID) !== null) {
      return get(propertyQuery(get(currentPropertyID)));
    }
  },
});
