import * as React from "react";
import { EventEmitter } from "./events";
import { request } from "./request";
import auth from "./auth/client";
import { preloadImage } from "./preload";

class Config extends EventEmitter {
  config: ReelConfig.Config = { loading: true };

  get reportLimitReached(): boolean {
    return !!this.config.reachedReportLimit;
  }

  get map() {
    return this.config.settings?.asset_map ?? "";
  }

  constructor() {
    super();
    this.init();
    auth.on("login:success", () => {
      this.init();
    });
    auth.on("logout:success", () => {
      this.destroy();
    });
  }

  async init() {
    this.on("load", () => {
      preloadImage(this.map);
    });

    if (
      (await auth.isAuthenticated()) &&
      (await auth.getUser()) &&
      !this.isLoaded()
    ) {
      this.load();
    }
  }

  /**
   * Flag that tells us if we have already fetched the user
   */
  isLoaded() {
    return !this.config.loading;
  }

  async load() {
    if (this.isLoaded()) {
      this.emit("load", this.config);
      return;
    }

    try {
      this.config = await request({
        endpoint:
          "/settings?include=conditions&include=preparationFlags&include=reachedReportLimit",
      });
      this.emit("load", this.config);
    } catch (e) {
      throw new Error("Config loading error");
    }
  }

  destroy() {
    this.config = { loading: true };
    this.emit("destroy");
  }
}

export const config = new Config();

export const useConfig = () => {
  const [, setConfig] = React.useState<any>(config);
  React.useEffect(() => config.on("load", setConfig), []);
  return config;
};
