import { atom } from "recoil";

const localStorageEffect =
  (key: string) =>
  // @ts-expect-error
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    // @ts-expect-error
    onSet((newValue, _, isReset) => {
      // @ts-expect-error
      window.reportState = newValue;
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const logEffect =
  (key: string) =>
  // @ts-expect-error
  ({ onSet }) => {
    onSet((newValue: unknown) => {
      console.log(`[${key}]`, newValue);
    });
  };

export type ReportInProgress = Partial<VehicleReportWithDamages> & {
  reportPreparationChecks?: PreparationFlag[];
};

export const reportInProgress = atom<ReportInProgress>({
  key: "ReportData",
  default: {},
  effects: [localStorageEffect("reportInProgress"), logEffect("ReportData")],
});

/*
√ fuel_in, 
√ fuel_out,
√ milage_in,
√ milage_out,
√ vehicle_id, (asset_id)
√ signature,
√ damages,
~ reportPreparationChecks //  rename preparationFlags to reportPreparationChecks,
√ customer_name,
√ customer_email,
√ reference_code,
√ overview_video,
~√ transfer (todo: implement transfers),
√ user_id,
√ location_id,
*/
