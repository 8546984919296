import { IonLoading } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../services/auth/client";

export const Logout: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    (async function () {
      await auth.logout();
      history.replace("/");
    })();
  }, [history]);

  return <IonLoading message={"Good bye..."} duration={15000} isOpen={true} />;
};

export default Logout;
