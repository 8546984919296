import * as React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router";
import { currentPropertyID, currentPropertyQuery } from "../state/property";
import { reportInProgress } from "../state/report";

const PropertySetter: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams<{ id: string }>();
  const setCurrentProperty = useSetRecoilState(currentPropertyID);
  const [report, setReport] = useRecoilState(reportInProgress);

  React.useEffect(() => {
    setCurrentProperty(id);
  }, [id, setCurrentProperty]);

  // create new report entry
  React.useEffect(() => {
    setReport({ ...report, asset_id: Number(id), vehicle_id: Number(id) });
  }, [id, setReport]);

  useRecoilValue(currentPropertyQuery);

  return <>{children}</>;
};

export default PropertySetter;
