import * as React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { auth } from "../services/auth/client";
import Authenticating from "../pages/auth/Authenticating";
import Private from "./Private";
import Public from "./Public";

const Routes: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    auth.isAuthenticated().then(setIsAuthenticated);
    auth.on("logout:success", () => setIsAuthenticated(false));
    auth.on("login:success", () => setIsAuthenticated(true));
  }, []);

  if (isAuthenticated === undefined) {
    return <Authenticating />;
  }

  return (
    <IonReactRouter>
      {isAuthenticated ? <Private /> : <Public />}
    </IonReactRouter>
  );
};

export default Routes;
